import car from '../../static/home-cate-link/01.png';
import rental from '../../static/home-cate-link/02.png';
import flea_market from '../../static/home-cate-link/03.png';
import job from '../../static/home-cate-link/04.png';
import maintenance from '../../static/home-cate-link/05.png';
import activity from '../../static/home-cate-link/06.png';
import shengyi from '../../static/home-cate-link/07.png';
import trip from '../../static/home-cate-link/08.png';
import tuantuan from '../../static/home-cate-link/09.png';
import merchant from '../../static/home-cate-link/10.png';
import social from '../../static/home-cate-link/11.png';

export const category_icon = [
  {
    title: '车辆买卖',
    link: '/car',
    value: 'car',
    category: 'cheliang',
    icon: car
  },
  {
    title: '租房中心',
    link: '/rentalHomePage',
    value: 'rental',
    category: 'fang',
    icon: rental
  },
  {
    title: '新货二手',
    link: '/ershou',
    value: 'flea_market',
    category: 'ershou',
    icon: flea_market
  },
  {
    title: '团团',
    link: '/generalHomePage/tuantuan',
    value: 'tuantuan',
    category: 'tuantuan',
    icon: tuantuan
  },
  {
    title: '招聘求职',
    link: '/jobHomePage',
    value: 'job',
    category: 'gongzuo',
    icon: job
  },
  {
    title: '本地服务',
    link: '/localServicesHomePage',
    value: 'maintenance',
    category: 'fuwu',
    icon: maintenance
  },
  {
    title: '同城活动',
    link: '/generalHomePage/huodong',
    value: 'activity',
    category: 'huodong',
    icon: activity
  },
  {
    title: '生意买卖',
    link: '/generalHomePage/shengyi',
    value: 'shengyi',
    category: 'shengyi',
    icon: shengyi
  },
  {
    title: '旅游门票',
    link: '/generalHomePage/lvyou',
    value: 'trip',
    category: 'lvyou',
    icon: trip
  },
  {
    title: '本地商家',
    link: '/merchant',
    value: 'social',
    category: 'social',
    icon: merchant
  }
];
